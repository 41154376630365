// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.T6PSOiHs6Cx51xHuD8d6{border:1px solid #d9d7d2;text-align:center;padding:24px;cursor:pointer;display:flex;flex-direction:column;justify-content:center;align-items:center;gap:24px}.T6PSOiHs6Cx51xHuD8d6 .sWwSOSGZYUOZymJYrIBl{height:170px;object-fit:contain;width:100%}@media(min-width: 0){.T6PSOiHs6Cx51xHuD8d6 .MhG5YXTbpjmZHmbf3JN6{font-size:2rem;line-height:2.4rem}}@media(min-width: 400px){.T6PSOiHs6Cx51xHuD8d6 .MhG5YXTbpjmZHmbf3JN6{font-size:2rem;line-height:2.4rem}}@media(min-width: 768px){.T6PSOiHs6Cx51xHuD8d6 .MhG5YXTbpjmZHmbf3JN6{font-size:2.6rem;line-height:2.8rem}}@media(min-width: 1366px){.T6PSOiHs6Cx51xHuD8d6 .MhG5YXTbpjmZHmbf3JN6{font-size:2.6rem;line-height:2.8rem}}.bhAHeoXA_pbJRg4wt5uT{border-width:5px;border-color:#fc3;padding:17px}`, ""]);
// Exports
export var machine = `T6PSOiHs6Cx51xHuD8d6`;
export var machineImage = `sWwSOSGZYUOZymJYrIBl`;
export var name = `MhG5YXTbpjmZHmbf3JN6`;
export var activeMachine = `bhAHeoXA_pbJRg4wt5uT`;
export default ___CSS_LOADER_EXPORT___;
