// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HAVQBnLrXkTfkMn8dhRi{display:flex;flex-direction:column;gap:8px;align-items:flex-start}.MNJpadROgz8hwr_pRtJg:after{width:24px;height:24px;display:inline-block;background-repeat:no-repeat;content:"";background-image:url("../assets/icons/Help-tip-gray.svg")}.MNJpadROgz8hwr_pRtJg{cursor:pointer;margin-left:10px;margin-bottom:2px;vertical-align:middle}.MNJpadROgz8hwr_pRtJg:after{background-size:24px 24px}`, ""]);
// Exports
export var wrapper = `HAVQBnLrXkTfkMn8dhRi`;
export var iconForHoveredText = `MNJpadROgz8hwr_pRtJg`;
export default ___CSS_LOADER_EXPORT___;
