import { useState } from "react";
import classnames from "classnames";
import { Machine } from "./Machine";
import { Model } from "./Model";
import { Type } from "./Type";
import * as styles from "./find-model.module.scss";
import { TMachine, TModel, TCatalog } from "../../types";


export const FindModel = () => {
    const preloadState = (window as any).app.preloadState.selectMachine;
    const translationState = (window as any).app.preloadState.translation;

    const [machineModels, setMachineModels] = useState<TModel[]>([]);
    const [machineCatalogs, setMachineCatalogs] = useState<TCatalog[]>([]);

    const [activeMachineCode, setActiveMachineCode] = useState<string | null>(null);
    const [activeMachineModelCode, setActiveMachineModelCode] = useState<string | null>(null);

    const handleCatalogClick = async (machineCatalog: TCatalog) => {
        await fetch(`/api/user/selected-catalog/${activeMachineModelCode}`, {
            headers: {
                "Content-Type": "application/json",
                "Swecon-Current-Language": (window as any).app.preloadState.currentLanguage
            },
            body: JSON.stringify({
                SalesModel: machineCatalog.name,
                CatalogIds: [
                    machineCatalog.code
                ],
                ModelVariant: machineCatalog.modelVariantName
            }),
            method: 'PUT'
        });

        window._paq?.push(['trackEvent', 'Buttons', 'Clicked', 'Machine Selection', machineCatalog.name]);

        const urlParams = new URLSearchParams(location.search);

        if (urlParams.get('goBack') === "true" && document.referrer) {
            const url = document.referrer;
            const char = url.includes('?') ? '&' : '?';
            location.assign(url.includes('explodedView') ? url : `${url}${char}explodedView=true`);
        } else {
            location.assign(preloadState.sparePartsUrl);
        }
    }

    const onMachineClickHandler = async (code: string) => {
        const response = await fetch(`/api/machines/${code}/models`,
            {
                headers: {
                    "Swecon-Current-Language": (window as any).app.preloadState.currentLanguage
                }
            });
        setMachineModels(await response.json());
        setActiveMachineCode(code);
        setActiveMachineModelCode(null);
        setMachineCatalogs([]);
        setTimeout(() => {
            document.querySelector("#machine-selection-models")?.scrollIntoView({ behavior: 'smooth' });
        }, 5);
    }

    const clickActiveMachineModelHandler = async (activeMachineModelCode: string) => {
        const response = await fetch(`/api/machines/model/${activeMachineModelCode}/catalogs`,
            {
                headers: {
                    "Swecon-Current-Language": (window as any).app.preloadState.currentLanguage
                }
            });
        setMachineCatalogs(await response.json());
        setActiveMachineModelCode(activeMachineModelCode);
        setTimeout(() => {
            document.querySelector("#machine-selection-types")?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }, 5);
    }

    return (
        <div className="machineSelectionNavigation">
            <p className="description firstLetterCapital">{translationState["machineSelection.selectMachineType"]}</p>
            <div className={classnames(styles.list)}>
                {preloadState.machines.map((machine: TMachine) => {

                    return (
                        <Machine
                            key={machine.code}
                            code={machine.code}
                            active={machine.code === activeMachineCode}
                            name={machine.name}
                            imageUrl={machine.imageUrl}
                            onClick={onMachineClickHandler}
                        />
                    );
                })}
            </div>

            {activeMachineCode !== null && (
                <Model models={machineModels} onClick={clickActiveMachineModelHandler} selectedCode={activeMachineModelCode} />
            )}

            {activeMachineModelCode !== null && <Type machines={machineCatalogs} onClick={handleCatalogClick} heading={translationState["machineSelection.selectModelType"]} hoveredTextDescription={preloadState.hoveredTextForSelectMachineType} />}
        </div>
    );
};
