import classNames from "classnames";
import { TRegisteredMachine } from "../../types";
import { Machine } from "./Machine";
import * as styles from "./machine-selection.module.scss";


export const MachineSelection = () => {
    const preloadState = (window as any).app.preloadState.selectMachine;
    const translationState = (window as any).app.preloadState.translation;
    return (
        <>
            <div className={classNames(styles.myMachinesHeader, "description")}>
                <p className={classNames("firstLetterCapital")}>{translationState["machineSelection.myRegisteredMachines"]}</p>
                {preloadState.addMachineButtonLabel && preloadState.addMachineButtonLink && <a className={classNames("btn btn--outlined--external")} href={preloadState.addMachineButtonLink}>{preloadState.addMachineButtonLabel}</a>}
            </div>
            {preloadState.registeredMachines?.length > 0 ? 
                <div className={styles.list}>
                    {preloadState.registeredMachines?.map((machine: TRegisteredMachine) => (
                        <Machine
                            id={machine.id}
                            key={machine.serialNumber}
                            serialNumber={machine.serialNumber}
                            imageUrl={machine.imageUrl}
                            operationModel={machine.operationModel}
                            customName={machine.customName}
                            salesModel={machine.salesModel}
                            modelVariant={machine.modelVariant}
                            catalogId={machine.catalogId}
                        />
                    ))}
                </div>
                :
                <div dangerouslySetInnerHTML={{__html: preloadState.emptyMyMachinesCatalogNote}}></div>
            }
        </>
    );
}
