import { TCatalog } from "../../../types";
import { MachineCatalog } from "../MachineCatalog/MachineCatalog";

import * as styles from "./type.module.scss";

type TypeProps = {
    machines: TCatalog[];
    selectedCode?: string | null;
    onClick: (machine: TCatalog) => void;
    heading: string;
    hoveredTextDescription: string | null;
};

export const Type = ({ machines, onClick, heading, hoveredTextDescription }: TypeProps) => {
    return (
        <>
            {heading && <p className="description firstLetterCapital" id="machine-selection-types">{heading}<span className={styles.iconForHoveredText} title={hoveredTextDescription ?? ""}></span></p>}
            <div className={styles.wrapper}>
                {machines.map((machine) => (
                    <MachineCatalog key={machine.code} machine={machine} clickHandler={onClick.bind(null, machine)} />
                ))}
            </div>
      </>
   
  );
};
