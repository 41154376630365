import classnames from "classnames";
import { TCatalog } from "../../types";
import * as styles from "./styles/visited.module.scss";

type VisitedMachineProps = {
    machine: TCatalog;
    clickHandler: (code: string) => void;
};

export const VisitedMachine = ({
  machine,
  clickHandler,
}: VisitedMachineProps) => (
    <div
        className={classnames(styles.item)}
        onClick={clickHandler.bind(null, machine.code)}
        tabIndex={0}
    >
        <strong className={styles.strong}>{machine.modelVariantName ? machine.modelVariantName : machine.name}</strong>
        {machine.modelVariantName && machine.name != machine.modelVariantName &&
             <span className={styles.catalogName}>{machine.name}</span>}
        {machine.code &&
            <span className={styles.serial}>#{machine.code}</span>}
    </div>
);
