import classnames from "classnames";

import * as styles from "./item.module.scss";

type ItemProps = {
    code: string;
  name: string;
  selected?: boolean;
    onClick?: (code: string) => void;
};

export const Item = ({
  name,
  code,
  selected = false,
  onClick = () => {},
}: ItemProps) => {
  return (
    <p
      className={classnames(styles.item, { [styles.selected]: selected })}
          onClick={onClick.bind(null, code)} tabIndex={0}
    >
      {name}
    </p>
  );
};
