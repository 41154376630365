import { useMemo, useState } from "react";
import { MachineSelection } from "./components/MachineSelection/MachineSelection";
import { FindModel } from "./components/FindModel";
import { Visited } from "./components/Visited";
import "./app.scss";
import { ButtonGroup } from "ui";

enum ViewType {
    Select,
    FindModel,
    LastVisited,
}

function App() {
    const preloadState = (window as any).app.preloadState.selectMachine;
    const translationState = (window as any).app.preloadState.translation;

    const [activeView, setActiveView] = useState<ViewType>(preloadState.loggedIn ? ViewType.Select : ViewType.FindModel);
    const links = useMemo(
        () => [
            {
                label: translationState["machineSelection.myMachines"],
                value: ViewType.Select,
                isVisible: preloadState.loggedIn
            },
            {
                label: translationState["machineSelection.findModel"],
                value: ViewType.FindModel,
                isVisible: true
            },
            {
                label: translationState["machineSelection.previouslyVisited"],
                value: ViewType.LastVisited,
                isVisible: true
            },
        ],
        []
    );

    const buildView = (view: ViewType) => {
        const getMainComponent = (view: ViewType) => {
            switch (view) {
                case ViewType.Select:
                    return <MachineSelection />;
                case ViewType.FindModel:
                    return <FindModel />;
                case ViewType.LastVisited:
                    return <Visited />;
            }
        };


        return (
            <div>{getMainComponent(view)}</div>
        );
    };

    return (
        <div className="page machineSelection">
            <div className="pageHeader">
                <h1 className="firstLetterCapital">{translationState["machineSelection.selectMachineHeading"]}</h1>
                <ButtonGroup initialSelected={activeView} items={links} onChecked={(value) => setActiveView(value)} />
            </div>
            {buildView(activeView)}
        </div>
    );
}

export default App;
