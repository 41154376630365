import { TRegisteredMachine, TCatalog } from "../../types";
import { Type } from "./Type";
import { usePromise } from 'ui';

export const Visited = () => {
    const preloadState = (window as any).app.preloadState.selectMachine;
    const translationState = (window as any).app.preloadState.translation;
    const { data, isLoading } = usePromise<TCatalog[]>(async () => {
        const response = await fetch("/api/user/recent-vehicles",
            {
                headers: {
                    "Swecon-Current-Language": (window as any).app.preloadState.currentLanguage
                }
            });
        if (response.ok) {
            const data: TRegisteredMachine[] = await response.json();
            return data.map(d => ({
                name: d.salesModel,
                code: d.serialNumber,
                operationModel: d.operationModel,
                range: {},
                url: "",
                modelVariantName: d.modelVariant,
                catalogId: d.catalogId
            })).reverse();
        } else {
            console.error(await response.text());
        }

        return [];
    }, []);

    if (isLoading) return null;

    const handleMachineTypeClick = async (machineModelType: TCatalog) => {
        await fetch("/api/user/selected-vehicle", {
            headers: {
                "Content-Type": "application/json",
                "Swecon-Current-Language": (window as any).app.preloadState.currentLanguage
            },
            body: JSON.stringify({
                OperationModel: machineModelType.operationModel,
                SalesModel: machineModelType.name,
                SerialNumber: machineModelType.code,
                ModelVariant: machineModelType.modelVariantName,
                CatalogIds: [
                    machineModelType.catalogId
                ],
            }),
            method: 'PUT'
        });

        window._paq?.push(['trackEvent', 'Buttons', 'Clicked', 'Machine Selection', machineModelType.name]);

        const urlParams = new URLSearchParams(location.search);

        if (urlParams.get('goBack') === "true" && document.referrer) {
            const url = document.referrer;
            const char = url.includes('?') ? '&' : '?';
            location.assign(url.includes('explodedView') ? url : `${url}${char}explodedView=true`);
        } else {
            location.assign(preloadState.sparePartsUrl);
        }
    }

    return (
        <div>
            {(data && data.length > 0) ? (
                <Type
                    machines={data!}
                    onClick={handleMachineTypeClick}
                    heading={translationState["machineSelection.yourLastVisitedMachines"]}
                    hoveredTextDescription={preloadState.hoveredTextForLastVisitedMachineType} />
            ) : (
                <p className="description firstLetterCapital">{preloadState.noPreviouslyVisitedMachinesYetText}</p>
            )}
        </div>
    );
};
