// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GjImXInP_sBo3h8dR1VS{box-sizing:border-box}.GjImXInP_sBo3h8dR1VS{background-color:#fff;border:5px solid #fff;padding:6px 52px 6px 15px;position:relative;cursor:pointer}.GjImXInP_sBo3h8dR1VS::after{display:inline-block;background-repeat:no-repeat;content:"";background-image:url("../assets/icons/Next.svg")}.GjImXInP_sBo3h8dR1VS::after{position:absolute;top:50%;right:20px;transform:translateY(-50%);width:28px;height:28px;background-size:contain}.IEOA41aTlSA6MCHhscwB{border-color:#fc3}`, ""]);
// Exports
export var item = `GjImXInP_sBo3h8dR1VS`;
export var selected = `IEOA41aTlSA6MCHhscwB`;
export default ___CSS_LOADER_EXPORT___;
